.button-container {
  display: inline-block;
}

.button-hint {
  font-size: 10px;
  margin-left: 2px;
  padding: 0;
  position: absolute;
}

.selected-image {
  z-index: 1;
  outline: solid #00da00 5px;
}

.batch-container {
  width: 100vh;
  height: 32vh;
}

.batch-image {
  max-width: 16vh;
  max-height: 16vh;
}

.batch-image-container {
  padding: 0;
}
