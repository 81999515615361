.button-container {
  display: inline-block;
}

.button-hint {
  font-size: 10px;
  margin-left: 2px;
  padding: 0;
  position: absolute;
}

.batch-text-markup {
  font-size: 3vh;
}
