body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media (min-width: 1200px) {
  .admin-container{
    max-width: 1700px;
  }
}
