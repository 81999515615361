.batch-container {
  height: 78vh;
  opacity: 0;
}

.loaded-batch-container {
  height: 78vh;
  opacity: 1.0;
  -webkit-transition-duration: -webkit-transform 0.1s;
  transition-duration: 0.1s;
}